<script setup>
  import { contactItems } from "@/assets/contactsInfo";
  import { ref, onMounted } from 'vue'
  import { sendContactFormReq } from "@/api";
  import SuccessMessage from "@/components/ui/messages/successMessage.vue";
  import useToggleVisibility from "@/hooks/useToggleVisible";
  import { IndexStore } from "@/store";
  const $store = IndexStore()
  const formUsers = ref([
    {
      name: 'Buyer',
      active: true,
      type: 0
    },
    {
      name: 'Supplier',
      active: false,
      type: 1
    }
  ])
  let form = ref([
    {
      name: 'Name',
      value: '',
      type: 'text',
      nameField: 'name'
    },
    {
      name: 'Country',
      value: '',
      type: 'text',
      nameField: 'country'
    },
    {
      name: 'Company name',
      value: '',
      type: 'text',
      nameField: 'company'
    },
    {
      name: 'E-mail',
      value: '',
      type: 'email',
      nameField: 'email'
    },
    {
      name: 'Phone number',
      value: '',
      type: 'phone',
      nameField: 'phone'
    },
    {
      name: 'Message',
      value: '',
      type: 'textarea',
      nameField: 'message'
    }
    
  ])
  const currentType = ref(0)
  const {
    toggleVisibility,
    setToggleVisibility,
    closeNotification
  } = useToggleVisibility()

  const changeFormUser = (i) => {
    formUsers.value.forEach(el => el.active = false)
    formUsers.value[i].active = true
    if (formUsers.value[i].active) {
      currentType.value = formUsers.value[i].type
    }
  }

  const sendForm = async () => {
    const dataForSend = [...form.value]
    const resultData = dataForSend.reduce((acc, current) => (
      { ...acc, [current.nameField]: current.value }
    ),{})
    resultData['type'] = currentType.value
    try {
      const res = await sendContactFormReq(resultData)
      if (res.data.success) {
        setToggleVisibility(true)
        closeNotification(true)
        form.value.forEach( el => {
          el.value = ''
        })
        formUsers.value.forEach( (el, idx) => {
          el.active = idx === 0 && !el.active;
        })
      }
    } catch (error) {
      console.log(error,'error ups')
    }
  }

  onMounted(() => {
    $store.$patch({
      isActiveHomePage: true
    })
  })

</script>

<template>
  <section class="contacts">
    <img src="@/assets/images/contacts-page/contact-bg.svg" alt="background-image" class="contacts__bg-1">
    <img src="@/assets/images/big-logo-gray.svg" alt="background-image-2" class="contacts__bg-2">
    <div class="container contacts-container">
      <div class="contacts__body">
        <div class="contacts__left">
          <h2 class="def-title title white mb-32">Contact</h2>
          <p class="contacts__subtitle subtitle def-description">You can use the buttons below to discuss specific topics or use the form on the right.</p>
          <div class="contacts__items">
            <div class="contacts__item"
                 v-for="(item, i) in contactItems"
                 :key="i"
            >
              <img class="contacts__item_img" :src="item.icon" alt="">
              <div class="contacts__item__description">
                <p class="contacts__item_text">{{ item.text }}</p>
                <a
                  v-for="subItem in item.data"
                  :key="subItem.id"
                  :href="subItem.href"
                  class="contacts__item_link"
                  :class="item.data.length > 1 ? 'underline' : ''"
                >
                  {{ subItem.title }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <form
          class="contacts__form" @submit.prevent="sendForm">
          <h3 class="contacts__form_title">Contact us</h3>
          <p
            v-if="!toggleVisibility"
            class="contacts__form_subtitle"
          >
            You are...
          </p>
          <SuccessMessage
            v-else
          />
          <div class="contacts__form_items">
            <div class="contacts__form_item"
                 v-for="(user, i) in formUsers"
                 :key="i"
                 @click="changeFormUser(i)"
                 :class="{'active' : user.active}"
            >
              {{ user.name }}
            </div>
          </div>
          <div class="contacts__form_inputs">
            <label for="" class="contacts__form_group" v-for="(item, i) in form" :key="i">
              <p class="contacts__form_group-text">{{ item.name }}</p>
              <textarea v-if="item.type === 'textarea'" v-model="item.value" class="contacts__form_textarea"></textarea>
              <input :type="item.type"
                     required
                     v-else
                     class="contacts__form_input"
                     v-model="item.value"
              >
            </label>
          </div>
          <button
            class="contacts__form_btn"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.contacts {
  background: $indigo;
  position: relative;
  padding-bottom: 3rem;
  box-sizing: border-box;
  height: 100%;
  max-height: 100rem;

  @media screen and (max-width: 900px) {
    max-height: 100%;
  }

  &__body {
    z-index: 3;
    position: relative;
    padding-top: 13.5rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    gap: 32px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding-top: 9.5rem;
    }
    @media screen and (max-width: 600px) {
      gap: 7px;
    }
  }
  &__left {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__bg-1 {
    width: 130rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 13rem;
    margin: auto;
    @media screen and (max-width: 1200px) {
      max-width: 130rem;
      width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }
  &__bg-2 {
    position: absolute;
    width: 100rem;
    top: -15rem;
    left: -20%;
    z-index: 3;
    @media screen and (max-width: 1279px) {
      max-width: 100rem;
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__items {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }
  &__item {
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 1.2rem 3.2rem;
    width: 54.4rem;
    background: linear-gradient(90deg, rgba(151, 71, 255, 0.3) 0%, rgba(92, 92, 92, 0) 100%);
    border-radius: 2rem;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    @media screen and (max-width: 374px) {
      padding: 1.2rem 1rem;
      gap: 5px;
    }
    &_img {
      width: 4rem;
      height: 4rem;
    }
    &_text {
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      color: $light;
      font-size: 2.1rem;
      line-height: 150%;
    }
    .contacts__item_link {
      font-weight: 400;
      font-family: 'Helvetica', sans-serif;
      line-height: 25px;
      color: #ECE3EE;
      font-size: 18px;
      &.underline {
        text-decoration: underline;
        margin-right: 15px;

        @media screen and (max-width: 374px) {
          margin-right: 5px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  
  
  &__form {
    padding: 3.2rem 12.8rem;
    background: rgba(92, 92, 92, 0.3);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(20px);
    @media screen and (max-width: 600px) {
      padding: 31px;
    }
    @media screen and (max-width: 374px) {
      padding: 15px;
    }
    &_title {
      font-family: 'Neue Machina';
      font-weight: 700;
      font-size: 2.1rem;
      color: $light;
    }
    &_subtitle {
      color: $light;
      opacity: 0.5;
      font-weight: 400;
      margin: .5rem 0;
    }
    &_items {
      display: flex;
      gap: .8rem;
      @media screen and (max-width: 374px) {
        flex-direction: column;
        width: 100%;
      }
    }
    &_item {
      font-family: 'Neue Machina';
      font-weight: 700;
      font-size: 1.8rem;
      color: $light;
      padding: 10px 0;
      width: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2rem;
      background: rgba(92, 92, 92, 0.3);
      cursor: pointer;
      box-sizing: border-box;
      @media screen and (max-width: 374px) {
        width: 100%;
        min-width: 100%;
      }
      &.active {
        background: rgba(115, 103, 240, 0.3);
      }
    }
    &_btn {
      background: $purple;
      width: 100%;
      border-radius: 5rem;
      font-size: 1.4rem;
      line-height: 140%;
      text-transform: uppercase;
      min-height: 5rem;
      margin-top: 2.4rem;
    }
    &_inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      margin-top: 1.6rem;
      height: 100%;
    }
    &_group {
      position: relative;
      height: auto;
    }
    &_group-text {
      font-weight: 400;
      font-size: 1.1rem;
      color: $light;
      opacity: 0.5;
      position: absolute;
      top: .8rem;
      left: 1.6rem;
    }
    &_input, &_textarea {
      background: rgba(66, 66, 66, 0.5);
      border: .1rem solid $white;
      height: 100%;
      width: 100%;
      color: $light;
      font-size: 1.4rem;
      padding: 2rem 1.6rem .5rem 1.6rem;
      box-sizing: border-box;
    }
    &_textarea {
      resize: none;
      height: 125px;
      outline: none;
      font-family: inherit;
    }

  }
  .contacts-container {
    @media screen and (max-width: 1200px) {
      width: auto;
      padding: 0 15px;
    }
  }
}
</style>
