<template>
  <div class="dot__body"
       :style="{top: item.top, left: item.left}"
       @mousemove="showInfo = true"
       @mouseleave="showInfo = false"
  >
    <div class="dot"></div>
    <div class="dot__info">
      <OfficeInfo
        v-if="showInfo"
        :item="item"
        :gray="true"
      />
    </div>
  </div>

</template>

<script setup>
import OfficeInfo from "@/components/contacts/OfficeInfo";
import {ref} from "vue"
import {defineProps} from "vue"

defineProps(['item'])

let showInfo = ref(false)
</script>

<style scoped lang="scss">
.dot__body {
  position: absolute;
  z-index: 3;

  @media screen and (max-width: 1275px) {
    &:nth-of-type(1) {
      top: 10.5rem !important;
    }
    &:nth-of-type(3) {
      top: 4.5rem !important;
      left: 28rem !important;
    }
    &:nth-of-type(4) {
      top: 4.7rem !important;
    }
  }
  @media screen and (max-width: 1100px) {
    &:nth-of-type(1) {
      top: 9rem !important;
      left: 9rem !important;
    }
    &:nth-of-type(3) {
      top: 3.5rem !important;
      left: 24rem !important;
    }
    &:nth-of-type(4) {
      top: 3.5rem !important;
    }
  }
  @media screen and (max-width: 900px) {
    &:nth-of-type(1) {
      top: 8rem !important;
      left: 7rem !important;
    }
    &:nth-of-type(3) {
      top: 2.5rem !important;
      left: 20rem !important;
    }
  }
  @media screen and (max-width: 768px) {
    &:nth-of-type(1) {
      top: 6rem !important;
      left: 5rem !important;
    }
    &:nth-of-type(2) {
      top: -1rem !important;
      left: -1rem !important;
    }
    &:nth-of-type(3) {
      left: 15rem !important;
    }
  }
  @media screen and (max-width: 600px) {
    &:nth-of-type(1) {
      top: 4rem !important;
      left: 4rem !important;
    }
    &:nth-of-type(3) {
      left: 12rem !important;
      top: 1rem !important;
    }
    &:nth-of-type(4) {
      top: 1.5rem !important;
      left: 0 !important;
    }
  }
  @media screen and (max-width: 500px) {
    &:nth-of-type(3) {
      left: 9rem !important;
      top: 0 !important;
    }
  }
  @media screen and (max-width: 400px) {
    &:nth-of-type(1) {
      top: 5.5rem !important;
      left: 2rem !important;
    }
    &:nth-of-type(3) {
      left: 12rem !important;
      top: 1rem !important;
    }
    &:nth-of-type(2) {
      left: -4rem !important;
    }
    &:nth-of-type(4) {
      top: 2rem !important;
      left: -3rem !important;
    }
  }
}
.dot {
  background: #FF4545;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  animation-name: dot;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  cursor: pointer;
  position: relative;
  &__info {
    position: absolute;
    left: 2.7rem;
    top: -6rem;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

@keyframes dot {
  0% {
    filter: drop-shadow(0px 0px 0rem rgba(255, 69, 69, 1));
  }
  10% {
    filter: drop-shadow(0px 0px 0.25rem rgba(255, 69, 69, 1));
  }
  20% {
    filter: drop-shadow(0px 0px 0.5rem rgba(255, 69, 69, 1));
  }
  30% {
    filter: drop-shadow(0px 0px 0.75rem rgba(255, 69, 69, 1));
  }
  40% {
    filter: drop-shadow(0px 0px 1rem rgba(255, 69, 69, 1));
  }
  50% {
    filter: drop-shadow(0px 0px 1.25rem rgba(255, 69, 69, 1));
  }
  60% {
    filter: drop-shadow(0px 0px 1.5rem rgba(255, 69, 69, 1));
  }
  70% {
    filter: drop-shadow(0px 0px 1.75rem rgba(255, 69, 69, 1));
  }
  80% {
    filter: drop-shadow(0px 0px 2rem rgba(255, 69, 69, 1));
  }
  100% {
  }
}
</style>
