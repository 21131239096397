export const offices = [
    {
      location: 'Al Baraha building, Dubai, UAE.',
      phone: '+971521703087',
      phoneHref: '12223334455',
      top: '12.5rem',
      left: '11rem'
    },
    {
      location: 'ul. Czerska 8/10, Warsaw, Poland.',
      phone: '+48660118796',
      phoneHref: '12223334455',
      top: 0,
      left: 0
    },
    {
      location: 'Beijing',
      // phone: '+1 222 333 44 55',
      phoneHref: '12223334455',
      soon: true,
      top: '5.5rem',
      left: '33rem'
    },
    {
      location: 'Istanbul',
      // phone: '+1 222 333 44 55',
      phoneHref: '12223334455',
      soon: true,
      top: '6.5rem',
      left: '1rem'
    }
  ]

export const contactItems = [
  {
    icon: require('@/assets/images/contacts-page/contact-icons/support.svg'),
    text: 'Have a question?',
    data: [{
      id: 1,
      title: 'info@wtw.network',
      href: 'info@wtw.network'
    }]
  },
  {
    icon: require('@/assets/images/contacts-page/contact-icons/partnership.svg'),
    text: 'Become our partner!',
    data: [{
      id: 1,
      title: 'partnership@wtw.network',
      href: 'partnership@wtw.network'
    }]
  },
  {
    icon: require('@/assets/images/contacts-page/contact-icons/question.svg'),
    text: 'Need support?',
    data: [{
      id: 1,
      title: 'support@wtw.network',
      href: 'support@wtw.network'
    }]
  },
  {
    icon: require('@/assets/images/contacts-page/contact-icons/follow.svg'),
    text: 'Follow us!',
    data: [
      {id: 1, title: 'Linkedin', href: '#'},
      {id: 2, title: 'Telegram', href: '#'},
      {id: 2, title: 'Facebook', href: '#'}
    ]
  }
]
